:root {
  /* Gradient styles */
  --gradient-color-primary: linear-gradient(180deg, #b3bdcf 0%, #831217 100%);
  --gradient-color-secondary: linear-gradient(180deg, #b3bdcf 0%, #831217 100%);
  --font-weight: bold;
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color1: #f12711;
  --card1-gradient-color2: #b3bdcf;
  --card2-gradient-color1: #7f00ff;
  --card2-gradient-color2: #e100ff;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;
  --table-bg-color:#091326;
  /** primary **/
  --ion-color-primary: #4da8f1;
  --ion-color-primary-rgb: 24, 187, 78;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-shade: #269b4d;
  --ion-color-primary-tint: #269b4d;

  --font-family: 'Jost', sans-serif;
  --font-family1: Bebas Neue;
  --common-dark: #000;
  --input-background-color: #131416;
  --input-text-color: #071123;

  /* Gradient styles */
  --gradient-color-primary: linear-gradient(
    180deg,
    var(--ion-color-primary) 0%,
    var(--ion-color-primary-shade) 100%
  );
  --gradient-color-secondary: linear-gradient(
    180deg,
    var(--ion-color-primary) 0%,
    var(--ion-color-primary-shade) 100%
  );
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    var(--gradient-color-primary) 3.56%,
    var(--gradient-color-secondary) 97.13%
  );
  --ion-card-gradient: linear-gradient(180deg, #444444 0%, #111111 100%);
  --ion-card-text: linear-gradient(180deg, #b3bdcf 0%, #b3bdcf 100%);
  --ion-color-primary: #4da8f1;
  --ion-color-primary-rgb: 225, 64, 122;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 144, 12, 30;
  --ion-color-primary-shade: #b3bdcf;
  --ion-color-primary-tint: #b3bdcf;

  /** secondary **/
  --table-head-text: #fff;

  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #303c61;
  --ion-color-secondary-tint: #4b577d;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #008000;
  --ion-color-success-rgb: 23, 221, 23;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #b3bdcf;
  --ion-color-warning-tint: #b3bdcf;

  /** danger **/
  --ion-color-danger: #aa151c;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #aa151c;
  --ion-color-danger-tint: #aa151c;
  --ion-color-lost: #cf022d;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-shade: #808289;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-button-border: #00349d;
  /** Sports List Navbar Colors **/
  --ion-nav-card-background: #071123;

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;

  /** Back and Lay **/
  --back-odd-background: #b2d9ff;
  --back-odd-background-light: #b2d9ff;
  --back-odd-background-rgb: rgb(14, 152, 211);
  --back-odd-background: #b2d9ff;
  --lay-odd-background: #ffc3dc;

  --back: #a3d9ff;
  --lay: #fbc8d1;
  /** Table colour **/
  --table-headers-light: rgba(32, 32, 32, 0.6);
  --table-body-background: #fff;

  /** Reports style **/
  --reports-bg-color: #ffffff;
  --page-titles-color: #ffffff;
  --reports-title-color: #ffffff;
  --filter-controls-border: #131416;

  /* Dashboard */
  --summary-card-text-color: #fff;

  /* input background color*/
  --input-bg-color:#eaeef3;

  /* Commission cards */
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color2: #b3bdcf;
  --card2-gradient-color1: #7f00ff;
  --card2-gradient-color2: #e100ff;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;

  /** Header Logo Sizes **/
  --logo-xl-width: 162px;
  --logo-xl-height: 58px;
  --logo-lg-width: 162px;
  --logo-lg-height: 58px;
  --logo-md-width: 162px;
  --logo-md-height: 48px;
  --logo-sm-width: 162px;
  --logo-sm-height: 48px;
  --logo-xs-width: 162px;
  --logo-xs-height: 48px;
}

.ios body,
.md body {
  --ion-background: #fff;
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-secondary: #071123;
  --ion-background-contrast: #fff;
  --ion-background-tertiary: #fff;
  --ion-color-secondary: var(--ion-background);
  --ion-background-color: var(--ion-background);
  --title-color: #071123;
  --text-table: #071123;
  --modal-bg-img1: var(--ion-background);
  --filter-border: #734a4b;
  --report-table-header: #071123;
  --report-table-border-color: #734a4b;
  --disable: #ddd;
  --disable-text: #fff;
  --table-border-color: #734a4b;
  --font-family: 'Jost', sans-serif;
  --font-family1: Bebas Neue;

  --ion-card-bg: #2e2e2e;
  --select-element-background: var(--ion-background);
  --ion-header-background: linear-gradient(180deg, #ed1b24 0%, #831217 100%);

  --text-color: #071123;
  --nodata-color:#000;
  --text-color-white: #fff;
  --header-bg: var(--ion-background);

  --ion-text-color: #e4ebff;
  --ion-text-light: #e4ebff;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;

  /** Card Variables**/
  --ion-card-background: var(--ion-background);
  --ion-card-background-secondary: var(--ion-background);
  --ion-card-text-color: #e4ebff;
  --ion-card-background-contrast: #e4ebff;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* drawer */

  --ion-drawer-background: #071123;
  --text-white: #fff;
  /** Popover Variables**/
  --ion-popover-background: #ffffff;
  --ion-popover--hover-background: #b3bdcf;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #231f20;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-header-background: #071123;
  --dialog-content-background: #fff;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #231f20;
  --date-picker-header-button-background: #231f20;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #fff;

  /** Input Field Styles **/
  --input-background-color: #231f20;
  --input-text-color: #000;

  --table-header-bg: linear-gradient(180deg, #b3bdcf 0%, #b3bdcf 100%);
  --table-header-text: #000;
  --table-text: #fff;
  --ion-color-primary-rgb: 219, 176, 61;
  --ion-color-primary-contrast: #fff;
  --header-bg: #231f20;
  --table-head: var(--ion-color-primary);
  --market-row: #74ccd2;
  --active-text: #000;
  --scorecard-bg: #231f20;
  --row-bg: #231f20;
  --profit-bg: #231f20;
  --loss-bg: #231f20;
}
